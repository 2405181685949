.gbModal {
  text-align: center;
}

.gbModal :global .ant-modal-content {
  min-width: 600px;
  max-width: 638px;
}

.countries {
  display: flex;
  align-items: center;
  justify-content: center;

}

.countries > button {
  margin-right: 0.5rem;
}

.countryButton {
  min-width: 100px;
  max-width: 200px;
  height: 70px;
  white-space: pre-wrap;
  align-items: center;
  display: flex;
  justify-content: center;
}

/*

.modalTitle {
  font-size: 1.5em;
  line-height: 1.1em;
  margin-top: 20px;
  margin-bottom: 0;
}

.modalForm {
  margin: 20px auto 40px;
  max-width: 420px;
  margin-top: 40px;
}

.selectGroup {
  max-width: 325px;
  border: none;
  position: relative;
  margin: 0 auto;
}

.modalSelect {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSelect :global .ant-select-selector {
  border-radius: 0;
  outline: 0;
  padding: 16px 0;
  width: 100%;
  height: auto !important;
}

.noFloatLabel :global .ant-select-selection-item {
  font-size: var(--title-size);
}

.selectGroup :global .ant-select.ant-select-show-search :global span.ant-select-arrow {
  top: unset;
  margin-top: 0px;
  width: 18px;
  height: 12px;
}

.selectGroup :global .ant-select-open.ant-select-show-search :global span.ant-select-arrow {
  transform: scale(-1, -1);
}

.selectGroup :global .ant-select-clear {
  margin-right: 5px;
}

.selectGroup :global .ant-select-selection-search .ant-select-selection-search-input {
  position: relative;
  margin-left: 18px!important;
  font-size: var(--title-size);
}

.selectGroup :global .ant-select-open.ant-select-show-search :global .ant-select-selector::before {
  color: var(--color-black);
  content: url(/assets-new/search-trigger.svg);
  font-size: 14px;
  height: 17px;
  width: 17px;
  left: 0.5em;
  top: 50%;
}

.selectGroup :global span.ant-select-clear {
  right: 2.2em;
  vertical-align: middle;
}

.modalSelect :global .ant-select-selector,
.modalSelect :global .ant-select-selector :global .ant-select-selection-item,
.modalSelect :global .ant-select-selector :global .ant-select-selection-item p,
.modalSelect :global .ant-select-selector :global .ant-select-selection-placeholder,
.modalSelect :global .ant-select-selector :global .ant-select-selection-search,
.modalSelect :global .ant-select-selector :global .ant-select-selection-search :global input
 {
  height: 52px !important;
  display: flex;
  align-items: center;
  margin: 0;
}

.modalSelect :global .ant-select-selector :global .ant-select-selection-search {
  top: 0px !important;
}

.modalSelect :global .ant-select-selector :global .ant-select-selection-item,
.modalSelect :global .ant-select-selector :global .ant-select-selection-item p {
  padding-left: 0px;
}

.selectGroup :global .ant-select-open :global .ant-select-selector :global .ant-select-selection-item p,
.selectGroup :global .ant-select-open :global .ant-select-selector :global .ant-select-selection-placeholder,
.selectGroup :global .ant-select-open :global .ant-select-selector :global .ant-select-selection-search
{
  padding-left: 6px;
}

.modalSelect div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchTrigger {
  border: 1px solid var(--color-grey-light);
  margin: 0 auto;
  position: relative;
  max-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.searchPlaceHolder {
  border: none;
  border-radius: 0;
  font-size: 14px;
  margin-left: 10px;
  padding: 16px 0;
  outline: none;
  width: 80%;
  box-shadow: none;
}

input.searchPlaceHolder:focus, input.searchPlaceHolder:active {
  box-shadow: none;
}

.searchPlaceHolder input {
  box-shadow: none;
  outline: none;
}

.worldWideLink {
  color: var(--color-black);
}

.worldWideLink:hover {
  color: var(--color-black);
}

button.submit {
  font-size: 0.8rem;
  margin-top: 41px;
  border-color: var(--color-grey-light);
  height: 50px !important;
}

button.submit:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}


*/
@media screen and (max-width: 767.5px) {
  .gbModal {
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0;
    width: 100% !important;
    max-width: none;
  }

  .gbModal :global .ant-modal-content {
    min-width: 0px;
    max-width: none;
  }
}
